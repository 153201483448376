import { Pagination } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as companyActions from './company.actions';
import { CompanyState, defaultPagination, initialState } from './company.state';

const reducer = createReducer(
  initialState,

  on(companyActions.createCompany, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(companyActions.createCompanySuccess, (state, { createdCompany }) => ({
    ...state,
    loading: false,
    // companies: [createdCompany, ...state.companies], -- pagination
    errorMessage: null,
  })),
  on(companyActions.createCompanyError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(companyActions.getCompany, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(companyActions.getCompanySuccess, (state, { company }) => ({
    ...state,
    loading: false,
    loadedCompany: company,
    errorMessage: null,
  })),
  on(companyActions.getCompanyError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    loadedCompany: null,
  })),

  on(companyActions.getCompanies, state => ({
    ...state,
    loading: true,
    companies: [],
    errorMessage: null,
  })),
  on(companyActions.getCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies: companies.data,
    pagination: companies.pagination,
    loading: false,
    errorMessage: null,
  })),
  on(companyActions.getCompaniesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    companies: [],
    pagination: { ...(defaultPagination as Pagination) },
  })),

  on(companyActions.updateCompany, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(companyActions.updateCompanySuccess, (state, { updatedCompany }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    companies: state.companies.map(company => (company.id === updatedCompany.id ? updatedCompany : company)),
    loadedCompany:
      state.loadedCompany && state.loadedCompany.id === updatedCompany.id ? updatedCompany : state.loadedCompany,
  })),
  on(companyActions.updateCompanyError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(companyActions.setIsActiveCompany, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(companyActions.setIsActiveCompanySuccess, (state, { updatedCompany }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    companies: state.companies.map(companyObj =>
      companyObj.id === updatedCompany.id ? { ...updatedCompany } : companyObj
    ),
    loadedCompany:
      state.loadedCompany && state.loadedCompany.id === updatedCompany.id ? updatedCompany : state.loadedCompany,
  })),
  on(companyActions.setIsActiveCompanyError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(companyActions.getCompanyEmployeesProcesses, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(companyActions.getCompanyEmployeesProcessesSuccess, (state, { employeesProcesses }) => ({
    ...state,
    employeesProcesses: employeesProcesses.data,
    employeesProcessesPagination: employeesProcesses.pagination,
    loading: false,
    errorMessage: null,
  })),
  on(companyActions.getCompanyEmployeesProcessesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  }))
);

export function companyReducer(state: CompanyState | undefined, action: Action): CompanyState {
  return reducer(state, action);
}
