export const environment = {
  production: false,
  API_URL: 'https://d3v.mobirel-bridge.com/bridge-api',
  SOCKET_URL: 'https://d3v.mobirel-bridge.com',
  version: require('../../package.json').version + '.dev',
  PAGE_TITLE: 'Residence Card App - Dev',

  // Times in seconds
  authAnimationTime: 3, // Loading Animation on Login Success
  minLoadingDataTime: 0.3, // Minimal time to load data, prevents immediate disappearance of the loading spinner etc.

  APP_NAME: 'Residence Card App - Dev',
  APP_URL: 'https://d3v.mobirel-bridge.com/',

  GOOGLE_ANALYTICS_ENABLED: false,
  GOOGLE_ANALYTICS_GTAG: '',
  ENABLED_PAYMENT_METHODS_GROUPS: [
    'fasttransfers',
    'blik',
    'wallet',
    'credit card',
    'google pay',
    'apple pay',
    'Installments',
  ],
};
