import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, of, combineLatest, timer } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap } from 'rxjs/operators';

import { ManagementHttpService } from '@core/http/management.http.service';
import { ProcessHttpService } from '@core/http/process.http.service';
import { UsersHttpService } from '@core/http/users.http.service';
import { VoivodeshipsHttpService } from '@core/http/voivodeships.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { parseError } from '@state/errors.parser';
import * as managementActions from './management.actions';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { environment } from '@environment';

@Injectable()
export class ManagementEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly managementHttp: ManagementHttpService,
    private readonly usersHttp: UsersHttpService,
    private readonly processHttp: ProcessHttpService,
    private readonly voivodeshipHttp: VoivodeshipsHttpService,
    private readonly notification: SnackbarService,
    private readonly nzMessage: NzMessageService,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {}

  searchUsersProcesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.searchUsersProcesses),
      switchMap(({ payload }) => {
        return this.managementHttp.searchUsersProcesses(payload).pipe(
          map(processes => {
            return managementActions.searchUsersProcessesSuccess({ processes });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.searchUsersProcesses.type);
            this.notification.showError(errorMessage);
            return of(managementActions.searchUsersProcessesError({ errorMessage }));
          })
        );
      })
    )
  );

  searchProcessesOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.searchProcessesOfUser),
      switchMap(({ payload }) => {
        return this.managementHttp.searchProcessesOfUser(payload).pipe(
          map(processesOfUser => {
            return managementActions.searchProcessesOfUserSuccess({ processesOfUser });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.searchProcessesOfUser.type);
            this.notification.showError(errorMessage);
            return of(managementActions.searchProcessesOfUserError({ errorMessage }));
          })
        );
      })
    )
  );

  getUserProcessBasicData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getUserProcessBasicData),
      switchMap(({ userProcessId }) => {
        const request$ = this.managementHttp.getUserProcessBasicData({ userProcessId });
        const delay$ = timer((environment.minLoadingDataTime || 0.5) * 1000);
        return combineLatest([request$, delay$]).pipe(
          map(([userProcessBasicData]) => {
            return managementActions.getUserProcessBasicDataSuccess({ userProcessBasicData });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcessBasicData.type);
            return of(managementActions.getUserProcessBasicDataError({ errorMessage }));
          })
        );
      })
    )
  );

  addNewUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.addNewUserProcess),
      switchMap(({ payload }) => {
        return this.managementHttp.addNewUserProcess(payload).pipe(
          map(newUserProcess => {
            return managementActions.addNewUserProcessSuccess({ newUserProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.addNewUserProcess.type);
            this.notification.showError(errorMessage);
            return of(managementActions.addNewUserProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  assignProcessType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.assignProcessType),
      switchMap(({ opts }) => {
        return this.managementHttp.assignProcessType(opts).pipe(
          map(userProcess => {
            return managementActions.assignProcessTypeSuccess({ userProcess });
          }),
          catchError(() => {
            this.notification.showError('ERRORS.UNABLE_TO_ASSIGN_PROCESS_TYPE');
            return of(managementActions.assignProcessTypeError());
          })
        );
      })
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getUser),
      switchMap(({ userId }) => {
        return this.usersHttp.getUser(userId).pipe(
          map(user => {
            return managementActions.getUserSuccess({ user });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUser.type);
            return of(managementActions.getUserError({ errorMessage }));
          })
        );
      })
    )
  );

  getUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getUserProcess),
      switchMap(getOpts => {
        return this.managementHttp.getUserProcess(getOpts).pipe(
          map(userProcess => {
            if (!userProcess?.id) {
              this.notification.showError('ERRORS.PROCESS_NOT_EXIST');
              return managementActions.getUserProcessError({
                errorMessage: 'USER_PROCESS_NOT_FOUND_OR_HAS_BEEN_DELETED',
              });
            }
            return managementActions.getUserProcessSuccess({ userProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcess.type);
            return of(managementActions.getUserProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  redirectOnGetUserProcessError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(managementActions.getUserProcessError),
        filter(action => action.errorMessage === 'USER_PROCESS_NOT_FOUND_OR_HAS_BEEN_DELETED'),
        tap(() => {
          this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  removeUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.removeUserProcess),
      switchMap(({ userProcessId }) => {
        return this.managementHttp.removeUserProcess(userProcessId).pipe(
          map(() => {
            return managementActions.removeUserProcessSuccess({ removedUserProcessId: userProcessId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.removeUserProcess.type);
            return of(managementActions.removeUserProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  restoreUserProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.restoreUserProcess),
      switchMap(({ userProcessId }) => {
        return this.managementHttp.restoreUserProcess(userProcessId).pipe(
          map(updatedUserProcess => {
            return managementActions.restoreUserProcessSuccess({ updatedUserProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.restoreUserProcess.type);
            return of(managementActions.restoreUserProcessError({ errorMessage }));
          })
        );
      })
    )
  );

  updateUserProcessBasicData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateUserProcessBasicData),
      switchMap(({ payloadWithUserId, userProcessId }) => {
        return this.managementHttp.updateUserProcessBasicData(userProcessId, payloadWithUserId).pipe(
          map(updatedUserProcess => {
            return managementActions.updateUserProcessBasicDataSuccess({ updatedUserProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.updateUserProcessBasicData.type);
            this.notification.showError(errorMessage);
            return of(managementActions.updateUserProcessBasicDataError({ errorMessage }));
          })
        );
      })
    )
  );

  getDetailsValidationConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getDetailsValidationConfig),
      switchMap(({ foreignerId, userProcessId }) => {
        return this.managementHttp.getPersonalDetailsValidationConfig(foreignerId, userProcessId).pipe(
          map(validationConfig =>
            managementActions.getDetailsValidationConfigSuccess({
              all: validationConfig.all,
              members: validationConfig.members,
              userProcessId,
            })
          ),
          catchError(() =>
            of(
              managementActions.getDetailsValidationConfigError({
                errorMessage: 'ERROR_FETCHING_DETAILS_VALIDATION_CONFIG',
              })
            )
          )
        );
      })
    )
  );

  getUserProcessDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getUserProcessDocuments),
      switchMap(getOpts => {
        return this.managementHttp.getUserProcessDocuments(getOpts).pipe(
          map(processDocuments => {
            return managementActions.getUserProcessDocumentsSuccess({
              processDocuments,
              userProcessId: getOpts.userProcessId,
            });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcessDocuments.type);
            return of(managementActions.getUserProcessDocumentsError({ errorMessage }));
          })
        );
      })
    )
  );

  updatePersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updatePersonalDetails),
      switchMap(({ userId, personalDetails, userProcessId }) => {
        return this.managementHttp.updatePersonalDetailsInUserProcess({ userId, userProcessId, personalDetails }).pipe(
          map(updatedUserProcess => managementActions.updatePersonalDetailsSuccess({ userId, updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.updatePersonalDetails.type);
            return of(managementActions.updatePersonalDetailsError({ errorMessage }));
          })
        );
      })
    )
  );

  setPersonalDetailsVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.setPersonalDetailsVerification),
      switchMap(({ userId, userProcessId, verified }) => {
        return this.managementHttp.setPersonalDetailsVerification({ userId, userProcessId, verified }).pipe(
          map(updatedUserProcess => managementActions.setPersonalDetailsVerificationSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.setPersonalDetailsVerification.type);
            return of(managementActions.setPersonalDetailsVerificationError({ errorMessage }));
          })
        );
      })
    )
  );

  changeUserProcessType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.changeProcessType),
      switchMap(opts => {
        return this.managementHttp.changeUserProcessType(opts).pipe(
          map(updatedUserProcess => managementActions.changeProcessTypeSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.changeProcessType.type);
            return of(managementActions.changeProcessTypeError({ errorMessage }));
          })
        );
      })
    )
  );

  changeUserProcessTypeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.changeProcessTypeSuccess),
      map(({ updatedUserProcess }) =>
        managementActions.getUserProcessDocuments({
          userId: updatedUserProcess.userId,
          userProcessId: updatedUserProcess.id,
        })
      )
    )
  );

  changeUserProcessStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.changeProcessStatus),
      switchMap(opts => {
        return this.managementHttp.changeUserProcessStatus(opts).pipe(
          map(updatedUserProcess => managementActions.changeProcessStatusSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.changeProcessType.type);
            return of(managementActions.changeProcessTypeError({ errorMessage }));
          })
        );
      })
    )
  );

  setUserDocumentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.setUserDocumentStatus),
      switchMap(opts => {
        return this.managementHttp.setUserDocumentStatus(opts).pipe(
          map(updatedUserDocument => managementActions.setUserDocumentStatusSuccess({ updatedUserDocument })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.setUserDocumentStatus.type);
            return of(managementActions.setUserDocumentStatusError({ errorMessage }));
          })
        );
      })
    )
  );

  generateDocumentsNow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.generateDocumentsNow),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.generateDocumentsNow(userId, userProcessId).pipe(
          map(() => managementActions.getDocumentsGenerationStatus({ userProcessId, userId })),
          catchError(error => of(managementActions.generateDocumentsNowError({ error })))
        );
      })
    )
  );

  generateDocumentsNowError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(managementActions.generateDocumentsNowError),
        tap(({ error }) => {
          let errorName = error?.toString();
          if (error?.error?.message) {
            errorName = error.error?.message;
          }
          this.notification.showError('USER_PROCESS.REQUEST-FOR-GENERATING-DOCUMENTS-FAILED', { errorName });
        })
      ),
    { dispatch: false }
  );

  getDocumentsGenerationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getDocumentsGenerationStatus),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.getDocumentsGenerationStatus(userId, userProcessId).pipe(
          map(status => managementActions.getDocumentsGenerationStatusSuccess({ userProcessId, status })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getDocumentsGenerationStatus.type);
            return of(managementActions.getDocumentsGenerationStatusError({ errorMessage }));
          })
        );
      })
    )
  );

  printAllDocumentsOfForeigner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.printAllDocumentsOfForeinger),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.printAllDocumentsOfForeinger(userId, userProcessId).pipe(
          map(file => {
            window.open(window.URL.createObjectURL(file));
            return managementActions.printAllDocumentsOfForeingerSuccess();
          }),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            return of(managementActions.printAllDocumentsOfForeingerError());
          })
        );
      })
    )
  );

  areDocsGenerated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.areDocsGenerated),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.areDocsGenerated(userId, userProcessId).pipe(
          map(({ generated }) => {
            return managementActions.areDocsGeneratedSuccess({ generated });
          }),
          catchError(() => {
            return of(
              managementActions.areDocsGeneratedError({ errorMessage: 'ERROR_CHECKING_IF_DOCUMENTS_GENERATED' })
            );
          })
        );
      })
    )
  );

  getMergedFileData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getMergedFileData),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.printAllDocumentsOfForeinger(userId, userProcessId).pipe(
          map(file => {
            return managementActions.getMergedFileDataSuccess({ fileLink: window.URL.createObjectURL(file) });
          }),
          catchError(() => {
            return of(managementActions.getMergedFileDataError({ errorMessage: 'DOWNLOAD_MERGED_FILE_ERROR' }));
          })
        );
      })
    )
  );

  sendDataToInpol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.sendDataToInpol),
      switchMap(({ opts }) => {
        const { userId, userProcessId } = opts;
        return this.managementHttp.sendDataToInpol(opts).pipe(
          map(() => managementActions.getDocumentsGenerationStatus({ userProcessId, userId })),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            this.notification.showError('SENDING_TO_INPOL_FAILED');
            return of(managementActions.sendDataToInpolError({ errorMessage: 'Sending to inpol failed' }));
          })
        );
      })
    )
  );

  sendDataToMos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.sendDataToMos),
      switchMap(({ opts }) => {
        const { userId, userProcessId } = opts;
        return this.managementHttp.sendDataToMos(opts).pipe(
          map(() => managementActions.getDocumentsGenerationStatus({ userProcessId, userId })),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            this.notification.showError('SENDING_TO_MOS_FAILED');
            return of(managementActions.sendDataToMosError({ errorMessage: 'Sending to inpol failed' }));
          })
        );
      })
    )
  );

  getUserProcessChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getUserProcessChanges),
      switchMap(({ userProcessId, changeType, changeAuthorId }) => {
        return this.managementHttp.getUserProcessChanges(userProcessId, changeType, changeAuthorId).pipe(
          map(userProcessChanges => {
            return managementActions.getUserProcessChangesSuccess({ userProcessChanges, userProcessId });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getUserProcessChanges.type);
            return of(managementActions.getUserProcessChangesError({ errorMessage, userProcessId }));
          })
        );
      })
    )
  );

  downloadErrorScreenshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.downloadErrorScreenshot),
      switchMap(({ screenshotPath }) => {
        return this.managementHttp.downloadErrorScreenshot(screenshotPath).pipe(
          map(file => {
            window.open(window.URL.createObjectURL(file));
            return managementActions.downloadErrorScreenshotSuccess();
          }),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            this.notification.showError('ERRORS.PROBLEM-DOWNLOADING-SCREENSHOT');
            return of(managementActions.downloadErrorScreenshotError());
          })
        );
      })
    )
  );

  downloadRecording$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.downloadINPOLRecording),
      switchMap(({ recordingPath }) => {
        return this.managementHttp.downloadRecording(recordingPath).pipe(
          map(file => {
            window.open(window.URL.createObjectURL(file));
            return managementActions.downloadINPOLRecordingSuccess();
          }),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            this.notification.showError('ERRORS.PROBLEM-DOWNLOADING-RECORDING');
            return of(managementActions.downloadINPOLRecordingError());
          })
        );
      })
    )
  );

  setVisitDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.setVisitDate),
      switchMap(({ type, ...opts }) => {
        return this.managementHttp.setVisitDate(opts).pipe(
          map(updatedUserProcess => managementActions.setVisitDateSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.setVisitDate.type);
            return of(managementActions.setVisitDateError({ errorMessage }));
          })
        );
      })
    )
  );

  deleteVisitDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.deleteVisitDate),
      switchMap(({ type, ...opts }) => {
        return this.managementHttp.deleteVisitDate(opts).pipe(
          map(updatedUserProcess => managementActions.deleteVisitDateSuccess({ updatedUserProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.deleteVisitDate.type);
            return of(managementActions.deleteVisitDateError({ errorMessage }));
          })
        );
      })
    )
  );

  confirmVoivoChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.confirmVoivoChange),
      switchMap(({ userId, userProcessId }) => {
        return this.managementHttp.confirmVoivoChange(userId, userProcessId).pipe(
          map(() => managementActions.confirmVoivoChangeSuccess({ userId, userProcessId })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.confirmVoivoChange.type);
            return of(managementActions.confirmVoivoChangeError({ userId, userProcessId, errorMessage }));
          })
        );
      })
    )
  );

  updateDocEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateDocEnabled),
      concatMap(({ opts }) => {
        return this.voivodeshipHttp.updateDocumentEnabled(opts).pipe(
          map(updatedDoc => managementActions.updateDocEnabledSuccess({ updatedDoc })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-UPDATING-ENABLED-DOC');
            return of(managementActions.updateDocEnabledError({ errorMessage: 'ERROR_UPDATING_VOIVODESHIP' }));
          })
        );
      })
    )
  );

  updateDocEnabledBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateDocEnabledBulk),
      concatMap(({ opts }) => {
        return this.voivodeshipHttp.updateDocumentsBulkEnabled(opts).pipe(
          map(updatedDocs => managementActions.updateDocEnabledBulkSuccess({ updatedDocs })),
          catchError(() => {
            this.notification.showError('ERRORS.UNKNOWN-ERROR-UPDATING-ENABLED-DOC');
            return of(managementActions.updateDocEnabledError({ errorMessage: 'ERROR_UPDATING_VOIVODESHIP' }));
          })
        );
      })
    )
  );

  updateForeignerDocumentQuestionAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateForeignerDocumentQuestionAnswer),
      concatMap(({ opts }) => {
        return this.managementHttp.updateForeignerDocumentQuestionAnswer(opts).pipe(
          map(updatedAnswer =>
            managementActions.updateForeignerDocumentQuestionAnswerSuccess({
              updatedAnswer,
              userProcessId: opts.userProcessId,
            })
          ),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.updateForeignerDocumentQuestionAnswer.type);
            this.notification.showError('ERRORS.ERROR_UPDATING_USER_ANSWER');
            return of(managementActions.updateForeignerDocumentQuestionAnswerError({ errorMessage }));
          })
        );
      })
    )
  );

  getItemsSentForFinalReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getItemsSentForFinalReview),
      switchMap(({ userProcessId }) => {
        return this.managementHttp.getItemsSentForFinalReview({ userProcessId }).pipe(
          map(userAssets => {
            return managementActions.getItemsSentForFinalReviewSuccess({ userAssets });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getItemsSentForFinalReview.type);
            return of(managementActions.getItemsSentForFinalReviewError({ errorMessage }));
          })
        );
      })
    )
  );

  downloadItemSentForFinalReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.downloadItemSentForFinalReview),
      switchMap(({ opts }) => {
        return this.managementHttp.downloadItemSentForFinalReview(opts).pipe(
          map(file => {
            window.open(window.URL.createObjectURL(file));
            return managementActions.downloadItemSentForFinalReviewSuccess();
          }),
          catchError(() => {
            // Show notifciation with error message
            // const errorMessage = parseError(error, userProcessActions.downloadFile.type);
            this.notification.showError('ERRORS.ERROR_DOWNLOADING_FILE');
            return of(managementActions.downloadItemSentForFinalReviewError());
          })
        );
      })
    )
  );

  updateDocumentsReviewComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateDocumentsReviewComment),
      concatMap(({ opts }) => {
        return this.managementHttp.updateDocumentsReviewComment(opts).pipe(
          map(userProcess => managementActions.updateDocumentsReviewCommentSuccess({ userProcess })),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.updateDocumentsReviewComment.type);
            this.notification.showError('ERRORS.ERROR_UPDATING_DOCUMENTS_REVIEW_COMMENT');
            return of(managementActions.updateDocumentsReviewCommentError({ errorMessage }));
          })
        );
      })
    )
  );

  getProcessWithDocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.getProcessWithDocs),
      switchMap(({ processId }) => {
        return this.processHttp.getProcessWithDocs(processId).pipe(
          map(process => {
            return managementActions.getProcessWithDocsSuccess({ process });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.getProcessWithDocs.type);
            return of(managementActions.getProcessWithDocsError({ errorMessage }));
          })
        );
      })
    )
  );

  updateUserProcessServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.updateUserProcessServices),
      switchMap(({ opts }) => {
        return this.managementHttp.updateIncludedServices(opts).pipe(
          map(updatedUserProcess => {
            return managementActions.updateUserProcessServicesSuccess({ updatedUserProcess });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.updateUserProcessServices.type);
            this.notification.showError(errorMessage);
            return of(managementActions.updateUserProcessServicesError({ errorMessage }));
          })
        );
      })
    )
  );

  isAnyProcessOfMineMissingAssistant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.isAnyProcessOfMineMissingAssistant),
      switchMap(() => {
        return this.managementHttp.isAnyProcessOfMineMissingAssistant().pipe(
          map(anyMissing => {
            return managementActions.isAnyProcessOfMineMissingAssistantSuccess({ anyMissing });
          }),
          catchError(error => {
            const errorMessage = parseError(error, managementActions.isAnyProcessOfMineMissingAssistant.type);
            this.notification.showError(errorMessage);
            return of(managementActions.isAnyProcessOfMineMissingAssistantError());
          })
        );
      })
    )
  );

  sendFollowUpEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managementActions.sendFollowUpEmail),
      switchMap(({ usersId, messages }) => {
        this.nzMessage.loading(this.translateService.instant('FOLLOW_UP.FOLLOW_UP_SENDING'), { nzDuration: 0 });

        return this.managementHttp.sendFollowUpEmail(usersId, messages).pipe(
          delay(2000),
          map(response => {
            this.nzMessage.remove();

            const failedEmails = response.filter(result => !result.success);

            if (failedEmails.length > 0) {
              failedEmails.forEach(failed => {
                this.nzMessage.error(
                  this.translateService.instant('NT3.ERROR_SENDING_MESSAGE', { userID: failed.userID })
                );
              });

              const errorMessage = failedEmails.map(failed => ({
                userID: failed.userID,
                error: failed.error || 'Unknown error',
              }));

              return managementActions.sendFollowUpEmailError({ errorMessage });
            } else {
              this.nzMessage.success(this.translateService.instant('FOLLOW_UP.FOLLOW_UP_SUCCESS'));
              return managementActions.sendFollowUpEmailSuccess();
            }
          }),
          catchError(error => {
            this.nzMessage.remove();
            this.nzMessage.error(this.translateService.instant('NT3.ERROR_SENDING_MESSAGE'));

            return of(managementActions.sendFollowUpEmailError({ errorMessage: error }));
          })
        );
      })
    )
  );
}
