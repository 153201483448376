import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AvailableLanguages, legalBasisOfWork } from '@constants';
import { Annex, ConsumableCountry, MOSSpeciality, UserProcess, Voivodeship } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AnnexFacade } from '@state/annex';
import * as moment from 'moment-timezone';
import { ReplaySubject, debounceTime, takeUntil, take } from 'rxjs';
import { blueCardCountries } from './blue-card-countries';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { ManagementFacade } from '@state/management';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-annex-form',
  templateUrl: './annex-form.component.html',
  styleUrls: ['./annex-form.component.scss'],
})
export class AnnexFormComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userProcess: UserProcess;
  @Input() annex: Annex;
  @Input() voivodeships: Voivodeship[];
  @Input() specialities: MOSSpeciality[];
  @Input() countries: ConsumableCountry[];

  @Output() annexChanged: EventEmitter<Partial<Annex>> = new EventEmitter();

  public loading$ = this.annexFacade.loading$;
  public legalBasisOfWork = legalBasisOfWork;

  public blueCardCountries = blueCardCountries;

  public currLang: AvailableLanguages;
  public form = this.fb.group({
    id: [null],
    workplace_usingPersonName: [null, Validators.compose([Validators.required])],
    workplace_name: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_surname: [null, Validators.compose([Validators.maxLength(1000)])],
    workplace_addressOutsideCountry: [null, Validators.compose([Validators.required])],
    workplace_addressVoivodeshipId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_addressDistrictId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_addressMunicipalityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_addressCityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_addressStreet: [null, Validators.compose([Validators.maxLength(1000)])],
    workplace_addressZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
    workplace_addressHouseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
    workplace_addressApartmentNumber: [null, Validators.compose([Validators.maxLength(15)])],
    workplace_externalAddressCountry: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_externalAddressCity: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_externalAddressStreet: [null, Validators.compose([Validators.maxLength(1000)])],
    workplace_externalAddressHouseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
    workplace_externalAddressApartmentNumber: [null, Validators.compose([Validators.maxLength(15)])],
    workplace_externalAddressZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
    workplace_legalBasisOfBusiness: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_pesel: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workplace_regon: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],

    hasSeparateEmployer: [null, Validators.compose([Validators.required])],

    employer_usingPersonName: [null, Validators.compose([])],
    employer_name: [null, Validators.compose([Validators.maxLength(1000)])],
    employer_surname: [null, Validators.compose([Validators.maxLength(1000)])],
    employer_addressOutsideCountry: [null, Validators.compose([])],
    employer_addressVoivodeshipId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_addressDistrictId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_addressMunicipalityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_addressCityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_addressStreet: [null, Validators.compose([Validators.maxLength(1000)])],
    employer_addressZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
    employer_addressHouseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
    employer_addressApartmentNumber: [null, Validators.compose([Validators.maxLength(15)])],
    employer_externalAddressCountry: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_externalAddressCity: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_externalAddressStreet: [null, Validators.compose([Validators.maxLength(1000)])],
    employer_externalAddressHouseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_externalAddressApartmentNumber: [null, Validators.compose([Validators.maxLength(1000)])],
    employer_externalAddressZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_legalBasisOfBusiness: [null, Validators.compose([Validators.required, Validators.maxLength(5000)])],
    employer_pesel: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    employer_regon: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],

    position: [null, Validators.compose([])],
    positionName: [
      null,
      Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(5000)]),
    ],

    place_addressVoivodeshipId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    place_addressDistrictId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    place_addressMunicipalityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    place_addressCityId: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    place_addressStreet: [null, Validators.compose([Validators.maxLength(1000)])],
    place_addressZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
    place_addressHouseNumber: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
    place_addressApartmentNumber: [null, Validators.compose([Validators.maxLength(15)])],
    legalBasisOfPaidActivity: [null, Validators.compose([Validators.required, Validators.maxLength(5000)])],

    contractType: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    workingTime: [null, Validators.compose([Validators.required, Validators.max(1000)])],
    workHoursPerWeek: [null, Validators.compose([Validators.required, Validators.max(1000)])],
    typeOfPayGross: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    grossAmount: [null, Validators.compose([Validators.required, Validators.max(1000000)])],
    mainDuties: [null, Validators.compose([Validators.required, Validators.maxLength(490)])],
    periodOfWorkFrom: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    periodOfWorkTo: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    qualificationsType: [null, Validators.compose([Validators.required, Validators.maxLength(5000)])],
    qualificationsDocument: [null, Validators.compose([Validators.required, Validators.maxLength(5000)])],

    hasBlueCard: [null, Validators.compose([Validators.required])],
    blueCardCountry: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    blueCardCountryPeriodOfStay: [null, Validators.compose([Validators.required, Validators.maxLength(5000)])],
    blueCardSerial: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    blueCardNumber: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    blueCardDateOfIssue: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    blueCardExpiryDate: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
    blueCardIssuer: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly annexFacade: AnnexFacade,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationModalService,
    private readonly managementFacade: ManagementFacade
  ) {}

  ngOnInit(): void {
    this.currLang = this.translateService.currentLang as AvailableLanguages;
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang }) => {
      this.currLang = lang as AvailableLanguages;
    });

    const updated: Annex = structuredClone(this.annex);

    // if does not exist/was not filled before and was filled in personalDetails
    const experienceFromPersData = this.userProcess.personalDetails.applyingForExperience;
    if (!this.annex.qualificationsType && experienceFromPersData) {
      updated.qualificationsType = experienceFromPersData;
    }
    // if does not exist/was not filled before and experience was filled in personal-data
    if (!this.annex.qualificationsDocument && experienceFromPersData) {
      updated.qualificationsDocument = 'Oryginały dokumentów';
    }

    this.form.patchValue(updated as any);

    this.handleNameChanges();

    this.form.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(annex => {
      const parsed: Partial<Annex> = structuredClone(annex);

      const powFromP = parsed.periodOfWorkFrom ? moment(parsed.periodOfWorkFrom) : null;
      const powToP = parsed.periodOfWorkTo ? moment(parsed.periodOfWorkTo) : null;

      const bcIssueDate = parsed.blueCardDateOfIssue ? moment(parsed.blueCardDateOfIssue) : null;
      const bcExpiryDate = parsed.blueCardExpiryDate ? moment(parsed.blueCardExpiryDate) : null;

      parsed.periodOfWorkFrom = powFromP ? powFromP.format('YYYY-MM-DD') : null;
      parsed.periodOfWorkTo = powToP ? powToP.format('YYYY-MM-DD') : null;

      parsed.blueCardDateOfIssue = bcIssueDate ? bcIssueDate.format('YYYY-MM-DD') : null;
      parsed.blueCardExpiryDate = bcExpiryDate ? bcExpiryDate.format('YYYY-MM-DD') : null;

      this.annexChanged.emit(parsed);
      this.annexFacade.updateAnnex({
        annex: parsed,
        annexId: parsed.id,
        userProcessId: this.userProcess.id,
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private handleNameChanges(): void {
    const { workplace_usingPersonName } = this.form.controls;

    workplace_usingPersonName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val) {
        this.form.controls.workplace_surname.setValidators(
          Validators.compose([Validators.required, Validators.maxLength(200)])
        );
      }
      if (!val) {
        this.form.controls.workplace_surname.setValidators(
          Validators.compose([Validators.required, Validators.maxLength(200)])
        );
      }
    });
  }

  public importAnnex(): void {
    if (!this.userProcess.user.company) {
      return;
    }

    this.confirmationService
      .open({
        confirmationToTranslate: 'NT2.ANNEX_IMPORT_TEXT',
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(res => !!res),
        switchMap(() =>
          this.managementFacade.userProcess$.pipe(
            filter(userProcess => !!userProcess?.id),
            take(1)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(userProcess => {
        const company = userProcess.user.company;

        const usingPersonName = company.workplace_usingPersonName;
        const outside = company.workplace_addressOutsideCountry;

        const fieldsForInsideCountry = [
          'workplace_addressVoivodeshipId',
          'workplace_addressDistrictId',
          'workplace_addressMunicipalityId',
          'workplace_addressCityId',
          'workplace_addressZipCode',
          'workplace_addressStreet',
          'workplace_addressHouseNumber',
          'workplace_addressApartmentNumber',
        ];

        const fieldsForOutsideCountry = [
          'workplace_externalAddressCountry',
          'workplace_externalAddressCity',
          'workplace_externalAddressStreet',
          'workplace_externalAddressZipCode',
          'workplace_externalAddressHouseNumber',
          'workplace_externalAddressApartmentNumber',
        ];

        const patchData: any = {
          workplace_usingPersonName: company.workplace_usingPersonName,
          workplace_addressOutsideCountry: company.workplace_addressOutsideCountry,
          workplace_name: company.workplace_name,
          workplace_surname: usingPersonName ? company.workplace_surname : null,
          workplace_pesel: company.workplace_pesel,
          workplace_regon: company.workplace_regon,
        };

        if (!outside) {
          fieldsForInsideCountry.forEach(field => {
            patchData[field] = (company as any)[field];
          });
        }

        if (outside) {
          fieldsForOutsideCountry.forEach(field => {
            patchData[field] = (company as any)[field];
          });
        }

        this.form.patchValue(patchData);
      });

    this.managementFacade.getUserProcess({ userId: this.userProcess.user.id, userProcessId: this.userProcess.id });
  }
}
