import {
  DocumentsGenerationStatus,
  ListedUserProcess,
  Pagination,
  Process,
  ProcessDocumentsList,
  User,
  UserAsset,
  UserProcess,
  UserProcessChange,
  ValidationConfig,
} from '@interfaces';

export type ManagementState = Readonly<{
  usersProcesses: ListedUserProcess[];
  usersProcessesPagination: Pagination;

  user: User;

  processesOfUser: ListedUserProcess[];
  processesOfUserPagination: Pagination;

  userProcess: ListedUserProcess;
  userProcessBasicData: Partial<UserProcess>;
  userProcessDocuments: {
    userProcessId: string;
    processDocumentsList: ProcessDocumentsList;
    documentsGenerationStatus: DocumentsGenerationStatus;
  };
  loadingDocuments: boolean;
  itemsSentForFinalReview: UserAsset[];
  loadingItemsInReview: boolean;

  loadingUserProcessChanges: boolean;
  userProcessChanges: {
    userProcessId: string;
    changes: UserProcessChange[];
  };

  processWithDocuments: Process;
  loadingProcess: boolean;

  loading: boolean;
  loadingBasicData: boolean;
  errorMessage: string;

  loadingFileData: boolean;

  personalDetailsValidationConfig: { userProcessId: string; all: ValidationConfig; members: ValidationConfig };
  anyMyProcessesMissingAssistant: boolean;
  checkingMissingAssistantInProgress: boolean;

  sendingEmail: boolean;
}>;

export const defaultPagination = {
  all: 0,
  limit: 50,
  offset: 0,
  orderBy: 'updatedAt',
  orderDir: 'desc',
};

export const initialState: ManagementState = {
  loading: false,
  errorMessage: null,
  usersProcesses: null,
  usersProcessesPagination: { ...(defaultPagination as Pagination) },
  processesOfUser: null,
  processesOfUserPagination: { ...(defaultPagination as Pagination) },
  user: null,
  userProcess: null,
  userProcessBasicData: null,
  userProcessDocuments: {
    userProcessId: null,
    processDocumentsList: null,
    documentsGenerationStatus: null,
  },
  itemsSentForFinalReview: [],
  loadingItemsInReview: false,
  loadingDocuments: false,
  loadingUserProcessChanges: false,
  userProcessChanges: {
    userProcessId: null,
    changes: null,
  },
  loadingFileData: false,
  processWithDocuments: null,
  loadingProcess: false,
  personalDetailsValidationConfig: null,
  anyMyProcessesMissingAssistant: false,
  checkingMissingAssistantInProgress: false,
  loadingBasicData: false,

  sendingEmail: false,
};
