import { BrandingType, Pagination, UserProcessStatus } from '@interfaces';

export interface Company {
  id?: string;
  email: string;
  name: string;
  branding: BrandingType;
  brandingLogo?: string;
  NIP?: string;
  address?: string;
  description?: string;
  ownerName?: string;
  purchaseEnabled: boolean;
  forceContactDataInApplication: boolean;
  forcedContactEmail?: string;
  forcedContactTelephoneNumber?: string;
  usingCustomAddress: boolean;
  customAddressCity?: string;
  customAddressStreet?: string;
  customAddressZipCode?: string;
  customAddressTelephoneNumber?: string;
  customAddressExtraInfo?: { [key: string]: string };
  customRecipientForPost?: { [key: string]: string };

  workplace_usingPersonName?: boolean;
  workplace_name?: string;
  workplace_surname?: string;
  workplace_addressOutsideCountry?: boolean;
  workplace_addressVoivodeshipId?: string;
  workplace_addressDistrictId?: string;
  workplace_addressMunicipalityId?: string;
  workplace_addressCityId?: string;
  workplace_addressZipCode?: string;
  workplace_addressStreet?: string;
  workplace_addressHouseNumber?: string;
  workplace_addressApartmentNumber?: string;
  workplace_externalAddressCountry?: string;
  workplace_externalAddressCity?: string;
  workplace_externalAddressStreet?: string;
  workplace_externalAddressZipCode?: string;
  workplace_externalAddressHouseNumber?: string;
  workplace_externalAddressApartmentNumber?: string;
  workplace_pesel?: string;
  workplace_regon?: string;

  isActive?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface GetCompanyPayload extends Pagination {
  query?: string;
  isActive?: boolean;
}

export interface GetCompanyEmployeesProcesses extends Pagination {
  status?: UserProcessStatus;
  query?: string;
}
