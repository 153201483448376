import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { AllDeviceInfo, DeviceType } from '@interfaces';

@Directive({ selector: '[appSwipe]' })
export class SwipeDirective {
  @Input() allowedDevices: DeviceType[] = [DeviceType.PHONE, DeviceType.TABLET];
  @Output() swipeLeft = new EventEmitter<void>();
  @Output() swipeRight = new EventEmitter<void>();

  private swipeCoord: [number, number] = [0, 0];
  private swipeTime: number = new Date().getTime();
  private isHorizontalScroll: boolean = false;

  private deviceInfo: AllDeviceInfo;

  constructor(private readonly deviceInfoService: DeviceInfoService) {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.subscribe(info => {
      this.deviceInfo = info;
    });
  }

  @HostListener('touchstart', ['$event']) onSwipeStart(event: TouchEvent) {
    if (!this.isDeviceAllowed()) {
      return;
    }

    this.isHorizontalScroll = false;
    this.swipeCoord = [event.touches[0].clientX, event.touches[0].clientY];
    this.swipeTime = new Date().getTime();
  }

  @HostListener('touchmove', ['$event']) onTouchMove(event: TouchEvent) {
    if (!this.isDeviceAllowed()) {
      return;
    }

    const deltaX = event.touches[0].clientX - this.swipeCoord[0];
    const deltaY = event.touches[0].clientY - this.swipeCoord[1];

    if (!this.isHorizontalScroll && Math.abs(deltaX) > Math.abs(deltaY)) {
      this.isHorizontalScroll = true;
    }

    if (this.isHorizontalScroll) {
      event.preventDefault();
    }
  }

  @HostListener('touchend', ['$event']) onSwipeEnd(event: TouchEvent) {
    if (!this.isDeviceAllowed()) {
      return;
    }

    if (this.isHorizontalScroll) {
      this.detectSwipe(event);
    }
  }

  private detectSwipe(event: TouchEvent): void {
    const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
    const time = new Date().getTime();
    const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
    const duration = time - this.swipeTime;

    if (
      duration < 1000 && //
      Math.abs(direction[0]) > 30 && // Long enough
      Math.abs(direction[0]) > Math.abs(direction[1] * 3)
    ) {
      // Horizontal enough
      const swipeDir = direction[0] < 0 ? 'next' : 'previous';
      if (swipeDir === 'next') {
        this.swipeLeft.emit();
      } else {
        this.swipeRight.emit();
      }
    }
  }

  private isDeviceAllowed(): boolean {
    return this.allowedDevices.includes(this.deviceInfo.deviceTypeDetected);
  }
}
