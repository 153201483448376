import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { LanguagesGuard } from '@core/guards/languages.guard';
import { VoivodeshipsGuard } from '@core/guards/voivodeships.guard';
import { PermissionEnum } from '@interfaces';
import { LayoutComponent } from '@layout/layout.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

import { IsForeignerGuard } from '@core/guards/is-foreigner.guard';
import { I18nLazyloadedResolver } from '@shared/i18n/i18n-lazyloaded-resolver';
import { AccountDeletedComponent } from './account-deleted/account-deleted.component';
import { ChatMobileComponent } from './chat/chat-mobile/chat-mobile.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { UserProcessFormComponent } from './user-process-form/user-process-form.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'user-settings',
        component: UserSettingsComponent,
        canActivate: [AuthGuard, LanguagesGuard, VoivodeshipsGuard],
        data: { requiredPermissions: [PermissionEnum.CAN_EDIT_OWN_DATA] },
      },
      {
        path: 'user-process-form',
        component: UserProcessFormComponent,
        canActivate: [AuthGuard],
        data: { requiredPermissions: [PermissionEnum.CAN_EDIT_OWN_DATA] },
      },
      {
        path: 'chat',
        component: ChatMobileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user-process',
        loadChildren: () => import('./user-process/user-process.module').then(m => m.UserProcessModule),
        resolve: { translations: I18nLazyloadedResolver },
        data: { moduleNames: ['user-process'] },
      },
      {
        path: 'management',
        loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
        resolve: { translations: I18nLazyloadedResolver },
        data: { moduleNames: ['management', 'user-process'] },
      },
      {
        path: 'manager',
        loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
        resolve: { translations: I18nLazyloadedResolver },
        data: { moduleNames: ['manager'] },
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        resolve: { translations: I18nLazyloadedResolver },
        data: { moduleNames: ['administration', 'management'] },
      },
      {
        path: 'purchase',
        canActivate: [IsForeignerGuard],
        loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule),
        resolve: { translations: I18nLazyloadedResolver },
        data: { moduleNames: ['purchase'] },
      },
      {
        path: 'partners',
        loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
        canActivate: [AuthGuard],
        resolve: { translations: I18nLazyloadedResolver },
        data: {
          moduleNames: ['management', 'user-process'],
          requiredPermissions: [PermissionEnum.CAN_MANAGE_USERS_PARTNER_ACCOUNT],
        },
      },
    ],
  },
  {
    path: 'back-office',
    loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule),
    resolve: { translations: I18nLazyloadedResolver },
    data: { moduleNames: ['back-office'] },
  },

  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'activate-account', component: ActivateAccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'new-account', component: NewAccountComponent },
  { path: 'account-deleted', component: AccountDeletedComponent },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
